<template>
  <div class="set-card">
    <van-form @submit="onSubmit">
      <van-cell-group style="margin-top: 12px" :border="false">
        <van-field
            v-model="formData.name"
            name="name"
            label="姓名"
            maxlength="10"
            placeholder="请输入"
            input-align="right"
            size="large"
            required
            :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
            v-model="formData.company"
            name="company"
            label="公司"
            maxlength="10"
            placeholder="请输入"
            input-align="right"
            size="large"
            required
            :rules="[{ required: true, message: '请输入公司' }]"
        />
        <van-field
            v-model="formData.job"
            name="job"
            label="职位"
            maxlength="10"
            placeholder="请输入"
            input-align="right"
            size="large"
            required
            :rules="[{ required: true, message: '请输入职位' }]"
        />
        <van-field
            v-model="formData.slogan"
            name="slogan"
            label="Slogan"
            maxlength="10"
            placeholder="请输入你的服务宣言"
            input-align="right"
            size="large"
            required
            :rules="[{ required: true, message: '请输入你的服务宣言' }]"
        />
      </van-cell-group>
      <div class="person-img">
        <div class="img-title">形象照</div>
        <div class="img-des">照片限制PNG、JPG格式，不能超过5M</div>
        <wxb-upload-img @imager="imager"/>
      </div>
      <van-button class="submit" type="info" native-type="submit">提交</van-button>
    </van-form>
  </div>
</template>

<script>
import mine from '@/api/mine';
import {Toast} from 'vant';

export default {
  name: 'setCard',
  data() {
    return {
      imageFile: '',
      formData: {
        name: '',
        company: '',
        job: '',
        slogan: '',
        picUrl: ''
      }
    };
  },
  methods: {
    //信息回显

    imager(val) {
      this.imageFile = val;
    },
    async onSubmit(values) {
      if (!this.imageFile) {
        Toast('请选择图片!');
        return;
      }
      let formData = new FormData();
      formData.append('file', this.imageFile);
      let {code, data} = await mine.uploadImg(formData);
      if (code === '0') {
        values.picUrl = data;
        let {code} = await mine.saveOwnerCard(values);
        if (code==='0') {
          this.$router.push('/myCard');
        }
      }
    },
  }
};
</script>

<style lang="less" scoped>
.set-card {
  .submit {
    width: 343px;
    margin: 40px 16px 0px;
  }

  .person-img {
    padding: 24px 16px;

    .img-title {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      color: @gary-wxb-gray-2;
      margin-bottom: 4px;
      position: relative;

      &:before {
        position: absolute;
        top: 0px;
        left: -8px;
        content: '*';
        color: @gary-wxb-error;
      }
    }

    .img-des {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: @gary-wxb-gray-3;
      line-height: 17px;
      margin-bottom: 12px;
    }
  }
}
</style>
